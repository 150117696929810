import { createApp } from "vue";
import Vuex from 'vuex';
import App from "./App.vue";
import router from "./router";
import AOS from 'aos';
import { VueMasonryPlugin } from 'vue-masonry';
import Swal from "sweetalert2";
import 'aos/dist/aos.css'
import "./assets/styles/tailwind.css";
import "./assets/styles/styles.css";
import "./assets/styles/style-font.css";
import "./assets/styles/global.css";
import { registerGlobalComponents } from "./utils/import";
import store from './store/store.js';
import { i18n, loadLanguageAsync } from '@/plugins/i18n-setup';
import TranslationHelper from './helpers/TranslationHelper.js'

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;
let aos = AOS.init({
    // easing: "ease-out-back",
    duration: 1500,
});
let lang = localStorage.getItem("locale");
lang = ['vi','en', 'ko'].includes(lang) ? lang : 'vi';
loadLanguageAsync(lang).then(() => {
    let app = createApp(App);
    app.config.productionTip = false
    app.mixin(TranslationHelper)
    registerGlobalComponents(app);
    app.config.globalProperties.$toast = Swal.mixin({
        toast: true,
        position: "end-center",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
    });
    app.use(aos);
    app.use(store);
    app.use(VueMasonryPlugin)
    app.use(i18n);
    app.use(Vuex);
    app.use(router);
    app.mount("#app");
})
