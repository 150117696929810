export const PUBLIC_LAYOUT = "default";
export const PROFILE_OPTIONS = [
  {
    name: "My Wallet",
    icon: "t2ico-wallet",
    route: {
      name: "Home",
      params: {},
    },
  },
  {
    name: "Tools",
    icon: "t2ico-ticket-star",
    route: {
      name: "Home",
      params: {},
    },
  },
];
