import Vuex from 'vuex';
const state = {
    locale: 'vi',
    menuActive: true,
    subActive: true,
    info: '',
    titleAlbum: [],
    dataLang: '',
}

const getters = {
    menuActive: state => state.menuActive,
    subActive: state => state.subActive,
    locale: state => state.locale,
    info: state => state.info,
    dataMenu: state => state.dataMenu,
    titleAlbum: state => state.titleAlbum,
    dataLang: state => state.dataLang,
}

const actions = {
    setLang({ commit }, locale) {
        commit('SET_LANG', locale);
        localStorage.setItem('locale', locale);
    },
    setMenu({ commit }, data) {
        commit("setMenuActive", data?.main);
        commit("setSubActive", data?.sub);
    },
}

const mutations = {
    setMenuActive(state, newNumber) {
        state.menuActive = newNumber;
    },
    setTitleAlbum(state, titleAlbum) {
        state.titleAlbum = titleAlbum;
    },
    setSubActive(state, newSub) {
        state.subActive = newSub;
    },
    setInfo(state, info) {
        state.info = info;
    },
    setDataMenu(state, dataMenu) {
        state.dataMenu = dataMenu;
    },
    SET_LANG(state, locale) {
        state.locale = locale;
    },
    setDataLang(state, dataLang) {
        state.dataLang = dataLang;
    }
}

export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
})


