/* global $ */
import { defineAsyncComponent } from "vue";

export function registerGlobalComponents(app) {
  app.component(
    "auth-layout",
    defineAsyncComponent(() => import("@/layouts/auth"))
  );

  app.component(
    "default-layout",
    defineAsyncComponent(() => import("@/layouts/default"))
  );

  app.component(
    "home-layout",
    defineAsyncComponent(() => import("@/layouts/home"))
  );
}

export function isScrolledIntoView(elem) {
  var $elem = $(elem);
  var $window = $(window);

  var docViewTop = $window.scrollTop();
  var docViewBottom = docViewTop + $window.height();

  var elemTop = $elem.offset().top;
  // var elemBottom = elemTop + $elem.height();
  // return elemBottom <= docViewBottom && elemTop >= docViewTop;
  return docViewBottom > elemTop + 200;
}

