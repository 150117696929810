import { createRouter, createWebHistory } from "vue-router";
//Auth guards
// const requireAuth = (to, from, next) => {
//   const user = projectAuth.currentUser;

//   if (!user) next({ name: "Login", params: {} });
//   else next();
// };

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      text: "Amber Hills",
      layout: "home",
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/index.vue"),
  },
  {
    path: "/golf-course",
    name: "Golf Course",
    meta: {
      text: "Golf Course",
    },
    component: () =>
      import(/* webpackChunkName: "course" */ "../views/golf-course.vue"),
  },
  {
    path: "/golf-course/detail",
    name: "Course Detail",
    meta: {
      text: "Course Detail",
    },
    component: () =>
      import(/* webpackChunkName: "course Detail" */ "../views/course-detail.vue"),
  },
  {
    path: "/price-list",
    name: "Price List",
    meta: {
      text: "Price List",
    },
    component: () =>
      import(/* webpackChunkName: "Price List" */ "../views/price-list.vue"),
  },
  {
    path: "/caddy",
    name: "Caddy",
    meta: {
      text: "Caddy",
    },
    component: () =>
      import(/* webpackChunkName: "Caddy" */ "../views/caddy.vue"),
  },
  {
    path: "/restaurant",
    name: "Restaurant",
    meta: {
      text: "Restaurant",
    },
    component: () =>
      import(/* webpackChunkName: "Restaurant" */ "../views/restaurant.vue"),
  },
  {
    path: "/other-utilites",
    name: "Other Utilites",
    meta: {
      text: "Other Utilites",
    },
    component: () =>
      import(/* webpackChunkName: "Other Utilites" */ "../views/other-utilites.vue"),
  },
  {
    path: "/biet-thu-nghi-duong",
    name: "Villa",
    meta: {
      text: "Villa",
    },
    component: () =>
      import(/* webpackChunkName: "Villa Rent" */ "../views/villa-rent.vue"),
  },
  {
    path: "/news",
    alias: ['/news/tin-tuc', '/news/tin-trong-nuoc', '/news/tin-tuc-san', '/news/tin-quoc-te'],
    name: "News",
    meta: {
      text: "News",
    },
    component: () =>
      import(/* webpackChunkName: "News" */ "../views/news.vue"),
  },
  {
    path: '/news/detail/tin-trong-nuoc',
    alias: ['/news/detail/tin-tuc-san', '/news/detail/tin-quoc-te'],
    name: "News 3",
    meta: {
      text: "News 3",
    },
    component: () =>
      import(/* webpackChunkName: "News 3" */ "../views/news.vue"),
  },
  {
    path: "/news/:slug",
    name: "News2",
    meta: {
      text: "News2",
    },
    component: () =>
      import(/* webpackChunkName: "News2" */ "../views/news2.vue"),
  },
  {
    path: "/news/ahihi",
    alias: '/news/tin-khuyen-mai',
    name: "Promos",
    meta: {
      text: "Promos",
    },
    component: () =>
      import(/* webpackChunkName: "Promos" */ "../views/promos.vue"),
  },
  {
    path: "/news/:slug/:slug",
    // alias: '/news/promos',
    name: "News Detail",
    meta: {
      text: "News Detail",
    },
    component: () =>
      import(/* webpackChunkName: "News Detail" */ "../views/news-detail.vue"),
  },
  {
    path: "/album/:slug",
    // alias: '/news/promos',
    name: "Library",
    meta: {
      text: "Library",
    },
    component: () =>
      import(/* webpackChunkName: "Library" */ "../views/library.vue"),
  },
  {
    path: "/member",
    name: "Member",
    meta: {
      text: "Member",
    },
    component: () =>
      import(/* webpackChunkName: "Member" */ "../views/member.vue"),
  },
  {
    path: "/member/VGA",
    name: "MemberVGA",
    meta: {
      text: "Member VGA",
    },
    component: () =>
      import(/* webpackChunkName: "Member" */ "../views/member.vue"),
  },
  {
    path: "/member/benefit",
    name: "Memberbenefit",
    meta: {
      text: "Member benefit",
    },
    component: () =>
      import(/* webpackChunkName: "Member" */ "../views/member.vue"),
  },
  {
    path: "/member/register",
    name: "Memberregister",
    meta: {
      text: "Member register",
    },
    component: () =>
      import(/* webpackChunkName: "Member" */ "../views/member.vue"),
  },
  {
    path: "/member/cross",
    name: "Membercross",
    meta: {
      text: "Member cross",
    },
    component: () =>
      import(/* webpackChunkName: "Member" */ "../views/member.vue"),
  },
  {
    path: "/contact",
    // alias: '/news/promos',
    name: "Contact",
    meta: {
      text: "Contact",
    },
    component: () =>
      import(/* webpackChunkName: "Contact" */ "../views/contact.vue"),
  },
  {
    path: "/contact/recruit",
    name: "Recruit",
    meta: {
      text: "Recruit",
    },
    component: () =>
      import(/* webpackChunkName: "Recruit" */ "../views/recruit.vue"),
  },
  {
    path: "/album",
    name: "Album",
    meta: {
      text: "Album",
    },
    component: () =>
      import(/* webpackChunkName: "Album" */ "../views/album.vue"),
  },
  {
    path: "/search",
    name: "Search",
    meta: {
      text: "Search",
    },
    component: () =>
      import(/* webpackChunkName: "Search" */ "../views/search.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
